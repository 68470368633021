<template>
    <b-row>
        <b-col md="12">
            <template>
                <div style="font-size:16px;" class="bg-primary">
                    <h5 class="text-white text-center"> {{ $t('external_research.implementation_location') + ' ' + $t('sidebar.information') }}
                    </h5>
                </div>
            </template>
            <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-3">
                            <!-- <b-col lg="6" sm="12">
                                <ValidationProvider name="Organization Name (En)" vid="org_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.org_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="location_details.org_name"
                                        id="org_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Organization Name (Bn)" vid="org_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.org_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="location_details.org_name_bn"
                                        id="org_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col> -->
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Division / Section (En)" vid="division" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="division"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.division_section_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="location_details.division"
                                        id="division"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Division / Section (Bn)" vid="division_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="division_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.division_section_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="location_details.division_bn"
                                        id="division_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Address (En)" vid="address">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.address_en')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="location_details.address"
                                        id="address"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Address (Bn)" vid="address_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.address_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="location_details.address_bn"
                                        id="address_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button v-if="locationIndex !== ''" variant="danger" class="mr-1" @click="cancelLocation()">{{ $t('globalTrans.cancel') }}</b-button>
                                    <b-button type="submit" variant="success">{{ locationIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <b-table-simple bordered>
                                <thead class="text-white bg-primary">
                                    <tr>
                                        <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                        <!-- <th scope="col">{{ $t('globalTrans.org_name') }}</th> -->
                                        <th scope="col">{{ $t('external_research.division_section') }}</th>
                                        <th scope="col">{{ $t('globalTrans.address') }}</th>
                                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                                    </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in location" :key="index">
                                        <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                        <!-- <b-td>{{ currentLocale == 'bn' ? item.org_name_bn : item.org_name }}</b-td> -->
                                        <b-td>{{ currentLocale == 'bn' ? item.division_bn : item.division }}</b-td>
                                        <b-td>{{ currentLocale == 'bn' ? item.address_bn : item.address }}</b-td>
                                        <b-td class="text-center">
                                            <span class="d-flex text-center">
                                                <b-button @click="editLocation(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                                <b-button type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                                            </span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="nodata">
                                        <td colspan="6" class="text-center">
                                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                        </td>
                                    </b-tr>
                                </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalLocationStoreApi } from '../../../api/routes'

export default {
    props: ['proposal_id', 'location'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            nodata: true,
            locationIndex: '',
            location_details: {
                // org_name: '',
                // org_name_bn: '',
                division: '',
                division_bn: '',
                address: '',
                address_bn: ''
            }
        }
    },
    created () {
        if (this.$route.query.id) {
            if (this.location.length === 0) {
                this.nodata = true
            } else {
                this.nodata = false
            }
        }
    },
    watch: {
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    mounted () {
    },
    methods: {
        cancelLocation () {
            this.location_details = {}
            this.locationIndex = ''
            this.$refs.form1.reset()
        },
        editLocation (item, index) {
            this.location_details = Object.assign({}, item)
            this.locationIndex = index
        },
        deleteItem (index) {
            this.location.splice(index, 1)
            if (this.location.length === 0) {
                this.nodata = true
            }
        },
        async addItem () {
            let result = []
            if (this.locationIndex !== '') {
                const editList = [...this.location]
                editList.splice(parseInt(this.locationIndex), 1)
                result = [...editList]
            } else {
                result = this.location
            }

            // const nameEn = this.location_details.org_name
            // const newData = result.find(item => item.org_name === nameEn)
            const divisionEn = this.location_details.division
            const newData = result.find(item => item.division === divisionEn)
            if (typeof newData === 'undefined') {
                if (this.locationIndex !== '') {
                    this.location[parseInt(this.locationIndex)] = this.location_details
                } else {
                    this.location.push(JSON.parse(JSON.stringify(this.location_details)))
                }

                this.location_details = {}
                this.location_details.division = ''
                this.location_details.division_bn = ''
                this.location_details.address = ''
                this.location_details.address_bn = ''
                this.locationIndex = ''
            } else {
                this.$toast.error({
                    message: this.$i18n.locale === 'en' ? 'Organization already exists' : 'সংস্থা ইতিমধ্যেই বিদ্যমান',
                    color: '#D6E09B'
                })
            }
            this.$nextTick(() => {
                this.$refs.form1.reset()
            })
            this.nodata = false
        },
        async submit () {
            if (this.location.length > 0) {
                // var check = await this.$refs.form.validate()
                // if (check) {
                    this.$store.dispatch('mutateCommonProperties', { loading: true })
                    const loadingState = { loading: false, listReload: false }
                    const locationData = {
                        proposal_id: this.proposal_id,
                        location: this.location
                    }
                    // this.investigator.proposal_id = this.proposal_id
                    const result = await RestApi.postData(agriResearchServiceBaseUrl, proposalLocationStoreApi, locationData)
                    loadingState.listReload = true
                    this.$store.dispatch('mutateCommonProperties', loadingState)
                    if (result.success) {
                        this.$toast.success({
                            title: this.$t('globalTrans.success'),
                            message: this.$t('globalTrans.save_msg'),
                            color: '#D6E09B'
                        })
                        return result
                    } else {
                        this.$toast.error({
                            title: this.$t('globalTrans.error'),
                            message: this.$t('globalTrans.form_error_msg'),
                            color: '#D6E09B'
                        })
                    }
                // }
            } else {
                this.$toast.error({
                    message: this.$i18n.locale === 'en' ? 'Add at least one item' : 'অন্তত একটি আইটেম যোগ করুন',
                    color: '#D6E09B'
                })
            }
        }
    }
}
</script>
