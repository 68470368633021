<template>
    <b-container fluid>
        <b-row>
            <b-col xl="12" lg="12" sm="12">
                <b-overlay :show="!isLoading">
                    <iq-card>
                        <template  v-slot:headerTitle>
                            <h4 class="card-title">{{ $t('external_research.proposal_submission') }}</h4>
                        </template>
                        <template v-slot:body>
                            <b-row v-if="dataLoaded">
                                <b-col>
                                    <form-wizard shape="circle" color="#005B5B" title="" subtitle="" :start-index.sync="activeIndex">
                                        <tab-content :title="$t('certify_form.gen_info')" icon="ri-home-4-line" :before-change="tabSubmitGeneralInfo" >
                                            <GeneralInfo v-if="isLoading" :proposal_id="proposal_id" :proposal="proposal" ref="general_info"/>
                                        </tab-content>
                                        <tab-content :title="$t('external_research.principal_investigator') + ' ' + $t('sidebar.information')" icon="ri-contacts-line" :before-change="tabSubmitInvestigator" >
                                            <Investigator v-if="isLoading" :proposal_id="proposal_id" :investigator="proposal.investigator" ref="investigator"/>
                                        </tab-content>
                                        <tab-content :title="$t('external_research.project_description')" icon="ri-play-list-add-line" :before-change="tabSubmitDescription">
                                            <Description v-if="isLoading" :proposal_id="proposal_id" :description="proposal.description" ref="description"/>
                                        </tab-content>
                                        <tab-content :title="$t('external_research.implementation_location')" icon="ri-map-pin-2-fill" :before-change="tabSubmitLocation">
                                            <Location v-if="isLoading" :proposal_id="proposal_id" :location="proposal.location" ref="location"/>
                                        </tab-content>
                                        <tab-content :title="$t('external_research.activity_list')" icon="ri-lightbulb-fill" :before-change="tabSubmitActivity">
                                            <span v-if="specificObjectiveList.length > 0">
                                                <Activity v-if="isLoading" :proposal_id="proposal_id" :activity="proposal.activity" :specificObjectiveList="specificObjectiveList" ref="activity"/>
                                            </span>
                                        </tab-content>
                                        <tab-content :title="$t('external_research.research_methodology')" icon="ri-list-settings-line" :before-change="tabSubmitMethodology">
                                            <Methodology v-if="isLoading" :proposal_id="proposal_id" :methodology="proposal.methodology" ref="methodology"/>
                                        </tab-content>
                                        <tab-content :title="$t('external_research.budget_info')" icon="ri-money-dollar-circle-fill" :before-change="tabSubmitBudget">
                                            <span v-if="proposal.from_date !== '' && proposal.to_date !== ''">
                                                <Budget v-if="isLoading" :proposal_id="proposal_id" :budget="proposal.budget" :from_date="proposal.from_date" :to_date="proposal.to_date" :application_status="application_status" ref="budget"/>
                                            </span>
                                        </tab-content>
                                        <template slot="footer" scope="props">
                                            <div class=wizard-footer-left>
                                                <wizard-button class="btn btn-secondary rounded" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                                            </div>
                                            <slot>
                                                <div class="wizard-footer-right">
                                                    <wizard-button
                                                        type="submit"
                                                        class="btn btn-primary ml-1 mr-1 rounded"
                                                        title="Create Draft and Save!"
                                                        @click.native="props.nextTab(), draft = 1"
                                                    >
                                                        {{$t('certify_form.save_as_draft')}}
                                                    </wizard-button>
                                                    <wizard-button
                                                        type="submit"
                                                        v-if="!props.isLastStep"
                                                        @click.native="props.nextTab(), isNext = true"
                                                        class="btn btn-danger ml-1 mr-1 rounded"
                                                        title="Save and go to next!"
                                                        :style="props.fillButtonStyle"
                                                    >{{$t('certify_form.save_and_next')}}
                                                    </wizard-button>
                                                    <wizard-button
                                                        v-else
                                                        type="submit"
                                                        class="btn btn-primary ml-1 mr-1 rounded"
                                                        title="Submit!"
                                                        @click.native="props.nextTab(), application_status = 1"
                                                    >
                                                        {{$t('certify_form.submit')}}
                                                    </wizard-button>
                                                </div>
                                            </slot>
                                        </template>
                                    </form-wizard>
                                </b-col>
                            </b-row>
                        </template>
                    </iq-card>
                </b-overlay>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
 import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { researchProposalDetailsApi, profileListData } from '../../../api/routes'
import GeneralInfo from './GeneralForm'
import Investigator from './InvestigatorForm'
import Description from './DescriptionForm'
import Location from './LocationForm'
import Activity from './ActivityForm'
import Methodology from './MethodologyForm'
import Budget from './BudgetForm'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
import { mapGetters } from 'vuex'

export default {
    props: ['id'],
    components: {
        GeneralInfo,
        Investigator,
        Description,
        Location,
        Activity,
        Methodology,
        Budget,
        FormWizard,
        TabContent,
        WizardButton
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        })
    },
    data () {
        return {
            saveBtnName: this.mat_id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            activeIndex: 0,
            loadItem1: false,
            loadItem2: false,
            loadItem3: false,
            loadItem4: false,
            loadItem5: true,
            loadItem6: false,
            proposal: {
                project_title: '',
                project_title_bn: '',
                from_date: '',
                to_date: '',
                research_type: 0,
                duration: 0,
                thematic_area_id: 0,
                org_id: 0,
                office_type_id: 0,
                office_id: 0,
                divisional_id: 0,
                org_type: 0,
                lead_org_id: 0,
                componentOrgs: [],
                investigator: {
                    org_type: 0,
                    tmp_com_org_id: 0,
                    component_org_id: 0,
                    co_investigator_name: '',
                    co_investigator_name_bn: '',
                    designation: '',
                    designation_bn: '',
                    address: '',
                    address_bn: '',
                    phone_no: '',
                    mobile_no: '',
                    email: '',
                    details: []
                },
                description: {
                    background: '',
                    background_bn: '',
                    goal: '',
                    goal_bn: '',
                    general_objective: '',
                    general_objective_bn: '',
                    literature_review: '',
                    literature_review_bn: '',
                    bench_mark_info: '',
                    bench_mark_info_bn: '',
                    targeted_beneficiaries: '',
                    targeted_beneficiaries_bn: '',
                    reference: ''
                },
                location: [],
                activity: [],
                methodology: {
                    approach_and_methodology: '',
                    approach_and_methodology_bn: '',
                    attachment: [],
                    attachment_file: '',
                    output: '',
                    output_bn: '',
                    outcome: '',
                    outcome_bn: ''
                },
                budget: {
                    total_budget: 0,
                    target_budget: '',
                    total_budget_perc: 0,
                    budget_head_breakdown_doc: [],
                    budget_head_breakdown_file: '',
                    attachment_file2: '',
                    details: []
                }
            },
            proposal_id: '',
            specificObjectiveList: [],
            isLoading: false,
            application_status: 0,
            draft: 0,
            dataLoaded: true
        }
    },
    watch: {
    },
    created () {
        this.loadAuthProfileData()
        if (this.$route.query.id) {
            this.dataLoaded = false
            this.proposal_id = this.$route.query.id
            this.getData()
        } else {
            this.isLoading = true
        }
        if (this.$route.query.circular_id) {
            this.dataLoaded = true
            this.isLoading = true
        }
    },
    methods: {
        async loadAuthProfileData () {
            const result = await RestApi.getData(agriResearchServiceBaseUrl, profileListData, { user_id: this.authUser.user_id })
            if (!result.success) {
                this.$router.push('/research-farmer/profile-update')
            }
        },
        async getData () {
            const result = await RestApi.getData(agriResearchServiceBaseUrl, researchProposalDetailsApi, { proposal_id: this.proposal_id })
            if (result.success) {
                this.dataLoaded = true
                const data = result.data
                if (data) {
                    this.proposal.project_title = data.project_title
                    this.proposal.project_title_bn = data.project_title_bn
                    this.proposal.from_date = data.from_date
                    this.proposal.to_date = data.to_date
                    this.proposal.research_type = data.research_type
                    this.proposal.thematic_area_id = data.invitation.thematic_area_id
                    this.proposal.circular_main_id = data.invitation.id
                    this.loadItem1 = true
                    this.activeIndex = 1

                    if (data.co_investigator) {
                        this.proposal.investigator = data.co_investigator
                        this.proposal.investigator.details = data.co_investigator.investigators
                        this.loadItem2 = true
                        this.activeIndex = 2
                    }
                    if (data.description) {
                        this.proposal.description = data.description
                        this.specificObjectiveList = data.description.details.map(item => {
                            return Object.assign({}, { value: item.id, text: this.$i18n.locale === 'en' ? item.specific_objective : item.specific_objective_bn })
                        })
                        this.loadItem3 = true
                        this.activeIndex = 3
                    }
                    if (data.locations.length > 0) {
                        this.proposal.location = data.locations
                        this.loadItem4 = true
                        this.activeIndex = 4
                    }
                    if (data.activities.length > 0) {
                        this.proposal.activity = data.activities
                        this.loadItem5 = true
                        this.activeIndex = 5
                    }
                    if (data.methodology) {
                        this.proposal.methodology.approach_and_methodology = data.methodology.approach_and_methodology
                        this.proposal.methodology.approach_and_methodology_bn = data.methodology.approach_and_methodology_bn
                        this.proposal.methodology.attachment_file = data.methodology.attachment
                        this.proposal.methodology.output = data.methodology.output
                        this.proposal.methodology.output_bn = data.methodology.output_bn
                        this.proposal.methodology.outcome = data.methodology.outcome
                        this.proposal.methodology.outcome_bn = data.methodology.outcome_bn
                        this.proposal.methodology.proposal_submission_attachment = data.methodology.proposal_submission_attachment
                        this.loadItem6 = true
                        this.activeIndex = 6
                    }
                    if (data.budget) {
                        this.proposal.budget.target_budget = data.budget.target_budget
                        this.proposal.budget.total_budget = data.budget.total_budget
                        this.proposal.budget.total_budget_perc = data.budget.total_budget_perc
                        this.proposal.budget.attachment_file2 = data.budget.budget_head_breakdown_doc
                        this.proposal.budget.details = data.budget.details
                    }
                    if (data.research_type === 3) {
                        this.proposal.org_id = data.org_id
                        this.proposal.office_type_id = data.office_type_id
                        this.proposal.office_id = data.office_id
                        this.proposal.divisional_id = data.divisional_id
                    }
                    if (data.research_type === 2) {
                        this.proposal.lead_org_id = data.lead_org_id
                        this.proposal.org_type = data.org_type
                        if (data.coordinator_orgs) {
                            this.proposal.componentOrgs = data.coordinator_orgs
                        }
                    }
                }
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.noDataFound'),
                    message: this.$t('globalTrans.operationFailed'),
                    color: '#D6E09B'
                })
            }
            this.isLoading = true
        },
        async tabSubmitBudget () {
            const result = await this.$refs.budget.submit()
            if (result.success) {
                this.$router.push({ path: 'proposal-submission' })
            }
        },
        async tabSubmitMethodology () {
            // if (this.loadItem6) {
            //     return true
            // } else {
                const result = await this.$refs.methodology.submit()
                if (result.success) {
                    if (this.draft) {
                        this.$router.push({ path: 'proposal-submission' })
                    } else {
                        this.loadItem6 = true
                        return true
                    }
                }
            // }
        },
        async tabSubmitActivity () {
            // if (this.loadItem5) {
            //     return true
            // } else {
                const result = await this.$refs.activity.submit()
                if (result.success) {
                    if (this.draft) {
                        this.$router.push({ path: 'proposal-submission' })
                    } else {
                        this.loadItem5 = true
                        return true
                    }
                }
            // }
        },
        async tabSubmitLocation () {
            // if (this.loadItem4) {
            //     return true
            // } else {
                const result = await this.$refs.location.submit()
                if (result.success) {
                    if (this.draft) {
                        this.$router.push({ path: 'proposal-submission' })
                    } else {
                        this.loadItem4 = true
                        return true
                    }
                }
            // }
        },
        async tabSubmitDescription () {
            // if (this.loadItem3) {
            //     return true
            // } else {
                const result = await this.$refs.description.submit()
                if (result.success) {
                    if (this.draft) {
                        this.$router.push({ path: 'proposal-submission' })
                    } else {
                        if (result.details) {
                            this.specificObjectiveList = result.details.map(item => {
                                return Object.assign({}, { value: item.id, text: this.$i18n.locale === 'en' ? item.specific_objective : item.specific_objective_bn })
                            })
                        }
                        this.loadItem3 = true
                        return true
                    }
                }
            // }
        },
        async tabSubmitInvestigator () {
            // if (this.loadItem2) {
            //     return true
            // } else {
                const result = await this.$refs.investigator.submit()
                if (result.success) {
                    if (this.draft) {
                        this.$router.push({ path: 'proposal-submission' })
                    } else {
                        this.loadItem2 = true
                        return true
                    }
                }
            // }
        },
        async tabSubmitGeneralInfo () {
            // if (this.loadItem1) {
                // this.$refs.general_info.validate()
            //     return true
            // } else {
                const result = await this.$refs.general_info.submit()
                if (result.success) {
                    if (this.draft) {
                        this.$router.push({ path: 'proposal-submission' })
                    } else {
                        this.loadItem1 = true
                        this.proposal_id = result.data.id
                        return true
                    }
                }
            // }
        }
    }
}
</script>
