<template>
    <b-row>
        <b-col md="12">
            <template>
                <div style="font-size:16px;" class="bg-primary">
                    <h5 class="text-white text-center"> {{ $t('external_research.activity_list') + ' ' + $t('sidebar.information') }}
                    </h5>
                </div>
            </template>
            <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-3">
                            <b-col lg="12" sm="12">
                                <ValidationProvider name="Specific Objective" vid="specific_obj_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="2"
                                        label-for="specific_obj_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('external_research.specific_objectives')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="activity_details.specific_obj_id"
                                        :options="specificObjectiveList"
                                        id="specific_obj_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Activity Name (En)" vid="activity_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="activity_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.activity_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="activity_details.activity_name"
                                        id="activity_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Activity Name (Bn)" vid="activity_name_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="activity_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.activity_name_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="activity_details.activity_name_bn"
                                        id="activity_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Start Date" vid="start_date" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="start_date"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="activity_details.start_date"
                                        placeholder="Select Start Date"
                                        id="start_date"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="End Date" vid="end_date" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="end_date"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="activity_details.end_date"
                                        placeholder="Select End Date"
                                        id="end_date"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Monitoring Indigator" vid="monitoring_indigator">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="monitoring_indigator"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.monitoring_indigator')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="activity_details.monitoring_indigator"
                                        id="monitoring_indigator"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Expected Output" vid="expected_output">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="expected_output"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.expected_output')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="activity_details.expected_output"
                                        id="expected_output"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                                <ValidationProvider name="Baseline Year" vid="baseline_year" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="baseline_year"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('external_research.baseline_year')}}
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="activity_details.baseline_year"
                                        :options="yearList"
                                        id="baseline_year"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Baseline Value" vid="baseline_value">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="baseline_value"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.baseline_value')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="activity_details.baseline_value"
                                        id="baseline_value"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Description" vid="description">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="description"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.description_en')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="activity_details.description"
                                        id="description"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Description" vid="description_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="description_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.description_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="activity_details.description_bn"
                                        id="description_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button v-if="editingIndex !== ''" variant="danger" class="mr-1" @click="cancel()">{{ $t('globalTrans.cancel') }}</b-button>
                                    <b-button type="submit" variant="success">{{ editingIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <b-table-simple bordered>
                                <thead class="text-white bg-primary">
                                    <tr>
                                        <th class="text-center" width="100px">{{ $t('external_research.activity_no') }}</th>
                                        <th scope="col">{{ $t('external_research.activity_name') }}</th>
                                        <th scope="col">{{ $t('globalTrans.start_date') }}</th>
                                        <th scope="col">{{ $t('globalTrans.end_date') }}</th>
                                        <th scope="col">{{ $t('external_research.monitoring_indigator') }}</th>
                                        <th scope="col">{{ $t('external_research.expected_output') }}</th>
                                        <th scope="col">{{ $t('external_research.baseline_year') }}</th>
                                        <th scope="col">{{ $t('external_research.baseline_value') }}</th>
                                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                                    </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in activity" :key="index">
                                        <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                        <b-td>{{ currentLocale == 'bn' ? item.activity_name_bn : item.activity_name }}</b-td>
                                        <b-td>{{ item.start_date | dateFormat }}</b-td>
                                        <b-td>{{ item.end_date | dateFormat }}</b-td>
                                        <b-td>{{ item.monitoring_indigator }}</b-td>
                                        <b-td>{{ item.expected_output }}</b-td>
                                        <b-td>{{ $n(item.baseline_year, { useGrouping: false }) }}</b-td>
                                        <b-td>{{ item.baseline_value }}</b-td>
                                        <b-td class="text-center">
                                            <b-button @click="editItem(item, index)" title="Activity Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="nodata">
                                        <td colspan="9" class="text-center">
                                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                        </td>
                                    </b-tr>
                                </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalActivityStoreApi } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['proposal_id', 'activity', 'specificObjectiveList'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            nodata: true,
            editingIndex: '',
            activity_details: {
                activity_name: '',
                activity_name_bn: '',
                start_date: '',
                end_date: '',
                specific_obj_id: 0,
                monitoring_indigator: '',
                expected_output: '',
                baseline_value: '',
                baseline_year: '',
                description: '',
                description_bn: ''
            }
        }
    },
    created () {
        if (this.$route.query.id) {
            if (this.activity.length > 0) {
                this.nodata = false
            } else {
                this.nodata = true
            }
        }
    },
    watch: {
    },
    computed: {
        yearList: function () {
            return this.$store.state.AgriResearch.commonObj.yearList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item, text: this.$n(item, { useGrouping: false }) }
                } else {
                    return item
                }
            })
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    mounted () {
        flatpickr('#start_date', {})
        flatpickr('#end_date', {})
        flatpickr('#edit_start_date', {})
        flatpickr('#edit_end_date', {})
    },
    methods: {
        editItem (item, index) {
            this.activity_details = Object.assign({}, item)
            this.editingIndex = index
        },
        deleteItem (index) {
            this.activity.splice(index, 1)
            if (this.activity.length === 0) {
                this.nodata = true
            }
        },
        cancel () {
            this.activity_details = {}
            this.editingIndex = ''
            this.activity_details.specific_obj_id = 0
            this.$refs.form1.reset()
        },
        async addItem () {
            // const nameEn = this.activity_details.activity_name
            // const nameBn = this.activity_details.activity_name_bn
            // const newData = this.activity.find(item => item.activity_name === nameEn && item.activity_name_bn === nameBn)
            // if (typeof newData === 'undefined') {
                if (this.editingIndex !== '') {
                    this.activity[parseInt(this.editingIndex)] = this.activity_details
                    // this.activity.push(JSON.parse(JSON.stringify(this.activity_details)))
                } else {
                    this.activity.push(JSON.parse(JSON.stringify(this.activity_details)))
                }
                this.activity_details = {}
                this.editingIndex = ''
                this.activity_details.specific_obj_id = 0
                this.activity_details.activity_name = ''
                this.activity_details.activity_name_bn = ''
                this.activity_details.start_date = ''
                this.activity_details.end_date = ''
                this.activity_details.monitoring_indigator = ''
                this.activity_details.expected_output = ''
                this.activity_details.baseline_value = ''
                this.activity_details.baseline_year = ''
                this.activity_details.description = ''
                this.activity_details.description_bn = ''
            // }
            this.$nextTick(() => {
                this.$refs.form1.reset()
            })
            this.nodata = false
        },
        async submit () {
            if (this.activity.length > 0) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                const locationData = {
                    proposal_id: this.proposal_id,
                    activity: this.activity
                }
                const result = await RestApi.postData(agriResearchServiceBaseUrl, proposalActivityStoreApi, locationData)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#D6E09B'
                    })
                }
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$i18n.locale === 'en' ? 'Add at least one item' : 'অন্তত একটি আইটেম যোগ করুন',
                    color: '#D6E09B'
                })
            }
        }
    }
}
</script>
