<template>
    <b-row>
        <b-col md="12">
            <template>
                <div style="font-size:16px;" class="bg-primary">
                    <h5 class="text-white text-center"> {{ $t('external_research.investigator_and_co_investigator') + ' ' + $t('sidebar.information') }}
                    </h5>
                </div>
            </template>
            <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-4">
                            <b-col sm="12" style="max-width: 50%">
                                <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                    {{ $t('external_research.principal_investigator') + ' ' + $t('sidebar.information') }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="6">
                                <ValidationProvider name="Organization Type" vid="tmp_org_type" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="tmp_org_type"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('research_manage.organization_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="investigator_details.org_type"
                                            :options="organizationType"
                                            id="tmp_org_type"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <ValidationProvider :name="investigator_details.org_type == 3 ? $t('research_manage.university_name', 'en') : $t('external_research.component_org', 'en')" vid="tmp_component_org_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="tmp_component_org_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{ investigator_details.org_type == 3 ? $t('research_manage.university_name') : $t('external_research.component_org')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="investigator_details.tmp_com_org_id"
                                            :options="tmpComponentOrgList"
                                            id="tmp_component_org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Principal Investigator Name (En)" vid="investigator_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="investigator_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.principal_investigator_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.investigator_name"
                                        id="investigator_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Principal Investigator Name (Bn)" vid="investigator_name_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="investigator_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.principal_investigator_name_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.investigator_name_bn"
                                        id="investigator_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Designation (En)" vid="designation" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="designation"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.designation_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.designation"
                                        id="designation"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Designation (Bn)" vid="designation_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="designation_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.designation_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.designation_bn"
                                        id="designation_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Address (En)" vid="address">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.address_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.address"
                                        id="address"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Address (Bn)" vid="address_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.address_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.address_bn"
                                        id="address_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Phone No" vid="phone_no" rules="numeric|max:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="phone_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.phone_no')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.phone_no"
                                        id="phone_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|numeric|max:11|min:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mobile_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.mobile_no"
                                        id="mobile_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                             <b-col lg="6" sm="12">
                                <ValidationProvider name="Email" vid="email" rules="required|email">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="email"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator_details.email"
                                        id="email"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button v-if="editIndex !== ''" variant="danger" class="mr-1" @click="cancel()">{{ $t('globalTrans.cancel') }}</b-button>
                                    <b-button type="submit" variant="success">{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <b-table-simple bordered>
                                <thead class="text-white bg-primary">
                                    <tr>
                                        <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                        <th scope="col">{{ $t('research_manage.org_or_univ_name') }}</th>
                                        <th scope="col">{{ $t('external_research.principal_investigator_name') }}</th>
                                        <th scope="col">{{ $t('globalTrans.designation') }}</th>
                                        <th scope="col">{{ $t('globalTrans.address') }}</th>
                                        <th scope="col">{{ $t('globalUserData.mobile_no') }}</th>
                                        <th scope="col">{{ $t('globalTrans.email') }}</th>
                                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                                    </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in investigator.details" :key="index">
                                        <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                        <b-td>
                                            {{ getComponentOrgName(item.org_type, item.component_org_id) }}
                                        </b-td>
                                        <b-td>{{ currentLocale == 'bn' ? item.investigator_name_bn : item.investigator_name }}</b-td>
                                        <b-td>{{ currentLocale == 'bn' ? item.designation_bn : item.designation }}</b-td>
                                        <b-td>{{ currentLocale == 'bn' ? item.address_bn : item.address }}</b-td>
                                        <b-td>{{ item.mobile_no }}</b-td>
                                        <b-td>{{ item.email }}</b-td>
                                        <b-td class="text-center">
                                            <span class="d-flex">
                                                <b-button @click="editItem(item, index)" title="Investigator Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                                <b-button type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                                            </span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="nodata">
                                        <td colspan="8" class="text-center">
                                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                        </td>
                                    </b-tr>
                                </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-4">
                            <b-col sm="12" style="max-width: 50%">
                                <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                    {{ $t('external_research.principal_co_investigator') + ' ' + $t('sidebar.information') }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="6">
                                <ValidationProvider name="Organization Type" vid="org_type" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="org_type"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('research_manage.organization_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="investigator.org_type"
                                            :options="organizationType"
                                            id="org_type"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <ValidationProvider :name="investigator.org_type == 3 ? $t('research_manage.university_name', 'en') : $t('external_research.component_org', 'en')" vid="component_org_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="component_org_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{ investigator.org_type == 3 ? $t('research_manage.university_name') : $t('external_research.component_org') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="investigator.tmp_com_org_id"
                                            :options="componentOrgList"
                                            id="component_org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Principal Co-investigator Name (En)" vid="co_investigator_name">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_investigator_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.principal_co_investigator_name_en')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.co_investigator_name"
                                        id="co_investigator_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Principal Co-investigator Name (Bn)" vid="co_investigator_name_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_investigator_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.principal_co_investigator_name_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.co_investigator_name_bn"
                                        id="co_investigator_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Designation (En)" vid="co_designation">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_designation"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.designation_en')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.designation"
                                        id="co_designation"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Designation (Bn)" vid="co_designation_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_designation_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.designation_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.designation_bn"
                                        id="co_designation_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Address (En)" vid="co_address">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_address"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.address_en')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.address"
                                        id="co_address"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Address (Bn)" vid="co_address_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.address_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.address_bn"
                                        id="co_address_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Phone No" vid="co_phone_no" rules="numeric|max:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_phone_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.phone_no')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.phone_no"
                                        id="co_phone_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Mobile No" vid="co_mobile_no" rules="numeric|max:11|min:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_mobile_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalUserData.mobile_no')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.mobile_no"
                                        id="co_mobile_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                             <b-col lg="6" sm="12">
                                <ValidationProvider name="Email" vid="co_email" rules="email">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="co_email"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.email')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="investigator.email"
                                        id="co_email"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalInvestigationStoreApi } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['proposal_id', 'investigator'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            nodata: true,
            investigator_details: {
                org_type: 0,
                tmp_com_org_id: 0,
                component_org_id: 0,
                investigator_name: '',
                investigator_name_bn: '',
                designation: '',
                designation_bn: '',
                address: '',
                address_bn: '',
                phone_no: '',
                mobile_no: '',
                email: ''
            },
            editIndex: '',
            editList: [],
            componentOrgList: [],
            tmpComponentOrgList: []
        }
    },
    created () {
        if (this.$route.query.id) {
            this.getLeadOrgList()
            if (this.componentOrgList.length > 0) {
                if (this.investigator.org_type === 1) {
                    const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === parseInt(this.investigator.component_org_id))
                    this.investigator.tmp_com_org_id = Obj.org_id
                } else {
                    this.investigator.tmp_com_org_id = this.investigator.component_org_id
                }
            }
            if (this.investigator.details.length > 0) {
                this.nodata = false
            } else {
                this.nodata = true
            }
        }
    },
    watch: {
        'investigator.org_type': function (newValue) {
            this.getLeadOrgList()
        },
        'investigator_details.org_type': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getTmpLeadOrgList()
            }
        },
        'investigator.tmp_com_org_id': function (newValue) {
            if (this.investigator.org_type === 1) {
                const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.org_id === parseInt(newValue))
                this.investigator.component_org_id = Obj.value
            } else {
                this.investigator.component_org_id = newValue
            }
        },
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getLeadOrgList()
                this.getTmpLeadOrgList()
            }
        }
    },
    computed: {
        organizationType: function () {
            return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.organizationType.map(item => {
                return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
            })
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    mounted () {
        flatpickr('#to_date', {})
        flatpickr('#from_date', {})
    },
    methods: {
        cancel () {
            this.investigator_details = {}
            this.investigator_details.org_type = 0
            this.investigator_details.tmp_com_org_id = 0
            this.investigator_details.component_org_id = 0
            this.tmpComponentOrgList = []
            this.editIndex = ''
            this.$refs.form1.reset()
        },
        editItem (item, index) {
            // this.investigator_details = item
            this.investigator_details = Object.assign({}, item)
            this.editIndex = index
            if (this.investigator_details.org_type === 1) {
                const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(newitem => newitem.value === parseInt(item.component_org_id))
                this.investigator_details.tmp_com_org_id = Obj.org_id
            } else {
                this.investigator_details.tmp_com_org_id = item.component_org_id
            }
        },
        deleteItem (index) {
            this.investigator.details.splice(index, 1)
            if (this.investigator.details.length === 0) {
                this.nodata = true
            }
        },
        async addItem () {
            const mobileNo = this.investigator_details.mobile_no
            const Email = this.investigator_details.email

            let result = []
            if (this.editIndex !== '') {
                this.editList = [...this.investigator.details]
                this.editList.splice(parseInt(this.editIndex), 1)
                result = [...this.editList]
            } else {
                result = this.investigator.details
            }

            const newData = result.find(item => item.email === Email && item.mobile_no === mobileNo)
            if (typeof newData === 'undefined') {
                if (this.investigator_details.org_type === 1) {
                    const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.org_id === parseInt(this.investigator_details.tmp_com_org_id))
                    this.investigator_details.component_org_id = Obj.value
                } else {
                    this.investigator_details.component_org_id = this.investigator_details.tmp_com_org_id
                }

                if (this.editIndex !== '') {
                    this.investigator.details[parseInt(this.editIndex)] = this.investigator_details
                } else {
                    this.investigator.details.push(JSON.parse(JSON.stringify(this.investigator_details)))
                }
                this.investigator_details = {}
                this.investigator_details.org_type = 0
                this.investigator_details.tmp_com_org_id = 0
                this.investigator_details.component_org_id = 0
                this.investigator_details.investigator_name = ''
                this.investigator_details.investigator_name_bn = ''
                this.investigator_details.designation = ''
                this.investigator_details.designation_bn = ''
                this.investigator_details.address = ''
                this.investigator_details.address_bn = ''
                this.tmpComponentOrgList = []
                this.editIndex = ''
            } else {
                this.$toast.error({
                    message: this.$i18n.locale === 'en' ? 'Item already exists' : 'আইটেম ইতিমধ্যেই বিদ্যমান',
                    color: '#D6E09B'
                })
            }
            this.$nextTick(() => {
                this.$refs.form1.reset()
            })
            this.nodata = false
        },
        getLeadOrgList () {
            this.componentOrgList = []
            if (this.investigator.org_type === 2 || this.investigator.org_type === 3) {
                this.componentOrgList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === parseInt(this.investigator.org_type))
                .map(obj => {
                    return Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                })
            } else {
                const tmpList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === 1)
                tmpList.map(newItem => {
                    this.$store.state.ExternalUserIrrigation.commonObj.orgList.filter(itm => itm.value === newItem.org_id)
                    .map(obj => {
                        const Org = Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                        this.componentOrgList.push(Org)
                    })
                })
            }
        },
        getTmpLeadOrgList () {
            this.tmpComponentOrgList = []
            if (this.investigator_details.org_type === 2 || this.investigator_details.org_type === 3) {
                this.tmpComponentOrgList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === parseInt(this.investigator_details.org_type))
                .map(obj => {
                    return Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                })
            } else {
                const tmpList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === 1)
                tmpList.map(newItem => {
                    this.$store.state.ExternalUserIrrigation.commonObj.orgList.filter(itm => itm.value === newItem.org_id)
                    .map(obj => {
                        const Org = Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                        this.tmpComponentOrgList.push(Org)
                    })
                })
            }
        },
        getComponentOrgName (type, Id) {
            if (type === 1) {
                const newObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                const Obj = this.$store.state.ExternalUserIrrigation.commonObj.orgList.find(item => item.value === newObj.org_id)
                return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
            } else {
                const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
            }
        },
        async submit () {
            if (this.investigator.details.length > 0) {
                var check = await this.$refs.form.validate()
                if (check) {
                    this.$store.dispatch('mutateCommonProperties', { loading: true })
                    const loadingState = { loading: false, listReload: false }
                    this.investigator.proposal_id = this.proposal_id
                    const result = await RestApi.postData(agriResearchServiceBaseUrl, proposalInvestigationStoreApi, this.investigator)
                    loadingState.listReload = true
                    this.$store.dispatch('mutateCommonProperties', loadingState)
                    if (result.success) {
                        this.$toast.success({
                            title: this.$t('globalTrans.success'),
                            message: this.$t('globalTrans.save_msg'),
                            color: '#D6E09B'
                        })
                        return result
                    } else {
                        this.$toast.error({
                            title: this.$t('globalTrans.error'),
                            message: this.$t('globalTrans.form_error_msg'),
                            color: '#D6E09B'
                        })
                    }
                }
            } else {
                this.$toast.error({
                    message: this.$i18n.locale === 'en' ? 'Add at least one item' : 'অন্তত একটি আইটেম যোগ করুন',
                    color: '#D6E09B'
                })
            }
        }
    }
}
</script>
