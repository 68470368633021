<template>
    <b-row>
        <b-col md="12">
            <template>
                <div style="font-size:16px;" class="bg-primary">
                    <h5 class="text-white text-center"> {{ $t('external_research.research_methodology') }}
                    </h5>
                </div>
            </template>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-4">
                            <b-col sm="12" style="max-width: 50%">
                                <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                    {{ $t('external_research.research_methodology') }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Approach and Methodology (En)" vid="approach_and_methodology_en" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="approach_and_methodology_en"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.approach_and_methodology_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="methodology.approach_and_methodology"
                                        id="approach_and_methodology_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Approach and Methodology (Bn)" vid="approach_and_methodology_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="approach_and_methodology_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.approach_and_methodology_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="methodology.approach_and_methodology_bn"
                                        id="approach_and_methodology_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6" sm="12">
                                <ValidationProvider name="Attachment" vid='attachment' :rules="!editId ? 'required' : ''">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('globalTrans.attachment')}} <span v-if="!editId && !methodology.attachment_file" class="text-danger">*</span>
                                            <span v-if="editId !== 0 && methodology.attachment_file">
                                                <a target="_blank" :href="baseUrl + 'storage/uploads/research-management/original/' + methodology.attachment_file" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                            </span>
                                        </template>
                                        <b-form-file
                                            id="attachment"
                                            accept="image/*"
                                            v-on:change="onFileChange"
                                            v-model="methodology.attachment"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-file>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col sm="12" style="max-width: 50%">
                                <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                    {{ $t('external_research.expected_output_outcome') }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Output (En)" vid="output" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="output"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.output_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="methodology.output"
                                        id="output"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Output (Bn)" vid="output_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="output_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.output_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="methodology.output_bn"
                                        id="output_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Outcome (En)" vid="outcome" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="outcome"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.outcome_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="methodology.outcome"
                                        id="outcome"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Outcome (Bn)" vid="outcome_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="outcome_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.outcome_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="methodology.outcome_bn"
                                        id="outcome_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                             <b-col md="6" sm="12">
                                <ValidationProvider name="Proposal Submission Attachment" vid='proposal_submission_attachment' :rules="!editId ? 'required' : ''">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="proposal_submission_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('external_research.proposal_submission_attachment')}} <span v-if="!editId && !methodology.proposal_submission_attachment" class="text-danger">*</span>
                                            <span v-if="editId !== 0 && methodology.proposal_submission_attachment">
                                                <a target="_blank" :href="baseUrl + 'storage/uploads/research-management/original/' + methodology.proposal_submission_attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                            </span>
                                        </template>
                                        <b-form-file
                                            id="proposal_submission_attachment"
                                            accept="file/*"
                                            v-on:change="proposalChange"
                                            v-model="methodology.proposal_submission_attachment_file"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-file>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalMethodologyStoreApi } from '../../../api/routes'

export default {
    props: ['proposal_id', 'methodology'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            upload_attachment: '',
            proposal_attachment: '',
            baseUrl: agriResearchServiceBaseUrl,
            editId: 0
        }
    },
    created () {
        if (this.$route.query.id) {
            this.editId = this.$route.query.id
        }
    },
    watch: {
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    mounted () {
    },
    methods: {
        onFileChange (e) {
            this.upload_attachment = e.target.files[0]
        },
        proposalChange (e) {
            this.proposal_attachment = e.target.files[0]
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const mainForm = Object.assign({}, { proposal_id: this.proposal_id }, this.methodology)
                var formData = new FormData()
                Object.keys(mainForm).map(key => {
                    if (key === 'attachment') {
                        formData.append(key, this.upload_attachment)
                    } else if (key === 'proposal_submission_attachment_file') {
                        if (this.proposal_attachment) {
                            formData.append('proposal_submission_attachment', this.proposal_attachment)
                        } else {
                           formData.append('proposal_submission_attachment', this.methodology.proposal_submission_attachment)
                        }
                    } else {
                        formData.append(key, mainForm[key])
                    }
                })
                const loadingState = { loading: false, listReload: false }
                const result = await RestApi.postData(agriResearchServiceBaseUrl, proposalMethodologyStoreApi, formData)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#D6E09B'
                    })
                }
            }
        }
    }
}
</script>
