<template>
    <b-row>
        <b-col md="12">
            <template>
                <div style="font-size:16px;" class="bg-primary">
                    <h5 class="text-white text-center"> {{ $t('external_research.project_description') }}
                    </h5>
                </div>
            </template>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-4">
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Background (En)" vid="background" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="background"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.background_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.background"
                                        id="background"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Background (Bn)" vid="background_en">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="background_en"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.background_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.background_bn"
                                        id="background_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Goal (En)" vid="goal" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="goal"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.goal_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.goal"
                                        id="goal"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Goal (Bn)" vid="goal_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="goal_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.goal_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.goal_bn"
                                        id="goal_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="General Objectives (En)" vid="general_objective" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="general_objective"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.general_objectives_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.general_objective"
                                        id="general_objective"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="General Objectives (Bn)" vid="general_objective_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="general_objective_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.general_objectives_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.general_objective_bn"
                                        id="general_objective_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Literature Review (En)" vid="literature_review" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="literature_review"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.literature_review_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.literature_review"
                                        id="literature_review"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Literature Review (Bn)" vid="literature_review_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="literature_review_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.literature_review_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.literature_review_bn"
                                        id="literature_review_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Bench Mark Information (En)" vid="bench_mark_info" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="bench_mark_info"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.bench_mark_info_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.bench_mark_info"
                                        id="bench_mark_info"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Bench Mark Information (Bn)" vid="bench_mark_info_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="bench_mark_info_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.bench_mark_info_bn')}}
                                    </template>
                                    <b-form-textarea class="form-control"
                                        v-model="description.bench_mark_info_bn"
                                        id="bench_mark_info_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Targeted Beneficiaries (En)" vid="targeted_beneficiaries" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="targeted_beneficiaries"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.targeted_beneficiaries_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="description.targeted_beneficiaries"
                                        id="targeted_beneficiaries"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                             <b-col lg="6" sm="12">
                                <ValidationProvider name="Targeted Beneficiaries (Bn)" vid="targeted_beneficiaries_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="targeted_beneficiaries_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.targeted_beneficiaries_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="description.targeted_beneficiaries_bn"
                                        id="targeted_beneficiaries_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row v-for="(item, index) in details" :key="index">
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Specific Objectives (En)" :vid="`specific_objective${index}`" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="specific_objective"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('external_research.specific_objectives_en')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea class="form-control"
                                            v-model="item.specific_objective"
                                            :id="`specific_objective${index}`"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Specific Objectives (Bn)" :vid="`specific_objective_bn${index}`">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="specific_objective_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('external_research.specific_objectives_bn')}}
                                        </template>
                                        <b-form-textarea class="form-control"
                                            v-model="item.specific_objective_bn"
                                            :id="`specific_objective_bn${index}`"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col class="mb-3">
                                <div class="text-right">
                                    <b-button v-if="(details.length - 1) === index" @click="addItem()" title="Add Item" class="btn btn-success btn-sm mr-1"><i class="fa fa-plus m-0"></i></b-button>
                                    <b-button v-if="details.length > 1" type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xl="12" lg="12" md="12" sm="12">
                            <ValidationProvider name="Reference" vid="reference" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="reference"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('external_research.reference')}} <span class="text-danger">*</span>
                                </template>
                                    <vue-editor
                                        id="reference"
                                        v-model="description.reference"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </vue-editor>
                                    <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalDescriptionStoreApi } from '../../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
    props: ['proposal_id', 'description'],
    components: {
        ValidationObserver,
        ValidationProvider,
        VueEditor
    },
    data () {
        return {
            details: [
                {
                    specific_objective: '',
                    specific_objective_bn: ''
                }
            ]
        }
    },
    created () {
        if (this.$route.query.id) {
            if (this.description.details) {
                this.details = [...this.description.details]
            }
        }
    },
    watch: {
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    mounted () {
    },
    methods: {
        addItem () {
            const myObj = {
                specific_objective: '',
                specific_objective_bn: ''
            }
            this.details.push(myObj)
        },
        deleteItem (index) {
            this.details.splice(index, 1)
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                this.description.proposal_id = this.proposal_id
                this.description.details = [...this.details]
                const result = await RestApi.postData(agriResearchServiceBaseUrl, proposalDescriptionStoreApi, this.description)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#D6E09B'
                    })
                }
            }
        }
    }
}
</script>
