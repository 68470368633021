<template>
    <b-row>
        <b-col md="12">
            <template>
                <div style="font-size:16px;" class="bg-primary">
                    <h5 class="text-white text-center"> {{ $t('external_research.budget_info') }}
                    </h5>
                </div>
            </template>
            <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-3">
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Target Budget" vid="target_budget" :rules="{required: true, regex: /^\d*\.?\d*$/}">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="target_budget"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.target_budget') + ' ' + $t('globalTrans.taka') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        :disabled="budget.details.length > 0 ? true : false"
                                        v-model="budget.target_budget"
                                        id="target_budget"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback d-block">
                                        {{ tmpMsg !== '' ? tmpMsg : errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <ValidationProvider name="Budget Type" vid="budget_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="budget_type_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('research_manage.budget_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="budget_details.budget_type_id"
                                            :options="budgetTypeList"
                                            id="budget_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <ValidationProvider name="Budget Head" vid="budget_head_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="budget_head_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('external_research.budget_head')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="budget_details.budget_head_id"
                                            :options="budgetHeadList"
                                            @change="getBudgetCode(budget_details.budget_head_id)"
                                            id="budget_head_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Code" vid="code" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="code"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.budget_code')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        disabled
                                        v-model="budget_details.head_code"
                                        id="code"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <ValidationProvider name="Expense Year" vid="expense_year" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="expense_year"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('external_research.expense_year')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="budget_details.expense_year"
                                            :options="expenseYearList"
                                            id="expense_year"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Allocation Amount"
                                    vid="allocation_amount"
                                    :rules="{required: true, max_value: budgetIndex !== '' ? parseFloat(budget.target_budget) - parseFloat(tmpTotal) :
                                    parseFloat(budget.target_budget) - parseFloat(budget.total_budget), regex: /^\d*\.?\d*$/}"
                                >
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="allocation_amount"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.allocation_amount') + ' ' + $t('globalTrans.taka') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="budget_details.allocation_amount"
                                        id="allocation_amount"
                                        @input="calculatePercentage(budget_details.allocation_amount)"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Budget Percentage" vid="budget_percentage" :rules="{required: true, regex: /^\d*\.?\d*$/}">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="budget_percentage"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.budget_percentage') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        disabled
                                        v-model="budget_details.budget_percentage"
                                        id="budget_percentage"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button v-if="budgetIndex !== ''" variant="danger" class="mr-1" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                                    <b-button type="submit" variant="success">{{ budgetIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <b-table-simple bordered>
                                <thead class="text-white bg-primary">
                                    <tr>
                                        <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                        <th scope="col">{{ $t('research_manage.budget_type') }}</th>
                                        <th scope="col">{{ $t('external_research.budget_head') }}</th>
                                        <th scope="col">{{ $t('external_research.budget_code') }}</th>
                                        <th scope="col">{{ $t('external_research.expense_year') }}</th>
                                        <th scope="col">{{ $t('external_research.budget_percentage') }}</th>
                                        <th scope="col">{{ $t('external_research.allocation_amount') + ' ' + $t('globalTrans.taka') }}</th>
                                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                                    </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in budget.details" :key="index">
                                        <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                        <b-td>{{ getBudgetTypeName(item.budget_type_id) }}</b-td>
                                        <b-td>{{ getBudgetName(item) }}</b-td>
                                        <b-td>{{ $n(item.code, { useGrouping: false }) }}</b-td>
                                        <b-td>{{ getExpenseYearName(item.expense_year) }}</b-td>
                                        <b-td>{{ $n(item.budget_percentage) }}</b-td>
                                        <b-td>{{ Number.isInteger(parseFloat(item.allocation_amount)) ? ($n(item.allocation_amount) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.allocation_amount) }}</b-td>
                                        <b-td class="text-center">
                                            <span class="d-flex text-center">
                                                <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                                <b-button type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                                            </span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="nodata">
                                        <td colspan="7" class="text-center">
                                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                        </td>
                                    </b-tr>
                                    <b-td colspan="6">
                                        <p class="text-right m-0 p-0">{{ $t('external_research.total_budget') + ' (%)' }}</p>
                                        <p class="text-right m-0 p-0">{{ $t('external_research.total_budget') + ' ' + $t('globalTrans.taka') }}</p>
                                    </b-td>
                                    <b-td class="bg-warning" colspan="2">
                                        <p class="text-left m-0 p-0">{{ $n(budget.total_budget_perc) }}</p>
                                        <p class="text-left m-0 p-0">{{ Number.isInteger(parseFloat(budget.total_budget)) ? ($n(budget.total_budget) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(budget.total_budget) }}</p>
                                    </b-td>
                                </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row class="mt-4">
                            <b-col sm="12" style="max-width: 50%">
                                <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                    {{ $t('external_research.budget_head_wise_breakdown') }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12">
                                <ValidationProvider name="Attachment" vid='budget_head_breakdown_file' :rules="!editId ? 'required' : ''">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="budget_head_breakdown_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('globalTrans.attachment')}} <span v-if="!editId && !budget.attachment_file2" class="text-danger">*</span>
                                            <span v-if="editId !== 0 && budget.attachment_file2">
                                                <a target="_blank" :href="baseUrl + 'storage/uploads/research-management/' + budget.attachment_file2" title="attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                            </span>
                                        </template>
                                        <b-form-file
                                            id="budget_head_breakdown_file"
                                            accept="application/*"
                                            v-on:change="onFileChange2"
                                            v-model="budget.budget_head_breakdown_doc"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-file>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalBudgetInfoStoreApi } from '../../../api/routes'

export default {
    props: ['proposal_id', 'budget', 'from_date', 'to_date', 'application_status'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            nodata: true,
            budgetIndex: '',
            expenseYearList: [],
            budgetHeadList: [],
            budget_details: {
                budget_type_id: 0,
                budget_head_id: 0,
                head_code: '',
                expense_year: 0,
                allocation_amount: '',
                budget_percentage: ''
            },
            tmpTotal: 0,
            editId: 0,
            tmpMsg: '',
            baseUrl: agriResearchServiceBaseUrl
        }
    },
    created () {
        if (this.$route.query.id) {
            if (this.budget.details.length > 0) {
                this.nodata = false
            } else {
                this.nodata = true
            }
            this.editId = this.$route.query.id
        }
        this.expenseYearList = this.getExpenseYearList()
    },
    watch: {
        from_date: function (newValue) {
            this.expenseYearList = this.getExpenseYearList()
        },
        to_date: function (newValue) {
            this.expenseYearList = this.getExpenseYearList()
        },
        'budget_details.budget_type_id': function (newValue) {
            this.getBudgetHeadList()
        },
         'budget.target_budget': function (newValue) {
            this.tmpMsg = ''
            this.calculatePercentage(this.budget_details.allocation_amount)
        },
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getBudgetHeadList()
            }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        budgetTypeList: function () {
            return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetTypeList.filter(item => item.status === 1).map(el => {
                return Object.assign({}, el, { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
            })
        }
    },
    mounted () {
    },
    methods: {
        calculatePercentage (allocAmount) {
            const Total = this.budgetIndex !== '' ? parseFloat(this.tmpTotal) + parseFloat(allocAmount) : parseFloat(this.budget.total_budget) + parseFloat(allocAmount)
            if (parseFloat(this.budget.target_budget) < Total) {
                this.budget_details.budget_percentage = ''
            } else {
                const Percentage = (parseFloat(allocAmount) * 100) / parseFloat(this.budget.target_budget)
                this.budget_details.budget_percentage = parseFloat(Percentage)
            }
        },
        cancelItem () {
            this.budget_details = {}
            this.budgetIndex = ''
            this.$refs.form1.reset()
        },
        editItem (item, index) {
            this.budget_details = Object.assign({}, item)
            this.getBudgetCode(this.budget_details.budget_head_id)
            this.budgetIndex = index
            this.tmpTotal = parseFloat(this.budget.total_budget) - parseFloat(item.allocation_amount)
            this.calculatePercentage(this.budget_details.allocation_amount)
        },
        getBudgetHeadList () {
            this.budgetHeadList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.filter(item => item.status === 1 && item.budget_type_id === parseInt(this.budget_details.budget_type_id)).map(obj => {
            if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getGrandTotal () {
            let sumM = 0
            let perc = 0
            this.budget.details.forEach(function (data) {
                sumM = sumM + parseFloat(data.allocation_amount)
                perc = perc + parseFloat(data.budget_percentage)
            })
            this.budget.total_budget = parseFloat(sumM)
            this.budget.total_budget_perc = parseFloat(perc)
        },
        getBudgetCode (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(item => item.value === Id)
            this.budget_details.head_code = Obj.budget_head_code
        },
        getBudgetTypeName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetTypeList.find(item => item.value === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        },
        getBudgetName (myItem) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(item => item.value === parseInt(myItem.budget_head_id))
            myItem.code = Obj.budget_head_code
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
             }
        },
        getExpenseYearName (Id) {
            const Obj = this.expenseYearList.find(item => item.value === Id)
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
             }
        },
        setCurrentFiscalYear (fiscalYearList, date) {
            if (fiscalYearList.length === 0) {
                return
            }
            const currentDate = new Date(date)
            const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
            /** Define the year position  */
            const yearPosition = month < 7 ? 5 : 0
            const yearStr = `${year}`
            let currentFiscalYearId = 0
            fiscalYearList.forEach(element => {
                if (element.text_en.indexOf(yearStr) === yearPosition) {
                    currentFiscalYearId = element.value
                }
            })
            return currentFiscalYearId
        },
        getExpenseYearList () {
            if (this.from_date !== '' && this.to_date !== '') {
                const tmpFiscalList = []
                const fiscalYear = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
                const startID = this.setCurrentFiscalYear(fiscalYear, this.from_date)
                const endId = this.setCurrentFiscalYear(fiscalYear, this.to_date)

                let start = false
                fiscalYear.forEach(element => {
                    if (startID === element.value) {
                        start = true
                    }
                    if (start) {
                        tmpFiscalList.push(element)
                    }
                    if (endId === element.value) {
                        start = false
                    }
                })
                return tmpFiscalList
            }
        },
        deleteItem (index) {
            this.budget.details.splice(index, 1)
            if (this.budget.details.length === 0) {
                this.nodata = true
            }
        },
        onFileChange2 (e) {
            const input = e.target
            const newFile = input.files[0]
            if (input.files && newFile) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.budget.budget_head_breakdown_file = e.target.result
                }
                reader.readAsDataURL(newFile)
            } else {
                this.budget.budget_head_breakdown_file = ''
            }
        },
        async addItem () {
            let result = []
            if (this.budgetIndex !== '') {
                const editList = [...this.budget.details]
                editList.splice(parseInt(this.budgetIndex), 1)
                result = [...editList]
            } else {
                result = this.budget.details
            }
            const budgetHead = this.budget_details.budget_head_id
            const expenseYear = this.budget_details.expense_year

            const newData = result.find(item => item.budget_head_id === budgetHead && item.expense_year === expenseYear)
            if (typeof newData === 'undefined') {
                if (this.budgetIndex !== '') {
                    this.budget.details[parseInt(this.budgetIndex)] = this.budget_details
                } else {
                    this.budget.details.push(JSON.parse(JSON.stringify(this.budget_details)))
                }
                this.budget_details = {
                    budget_head_id: 0,
                    code: '',
                    expense_year: 0,
                    allocation_amount: ''
                }
                this.budgetIndex = ''
                this.getGrandTotal()
            }
            this.$nextTick(() => {
                this.$refs.form1.reset()
            })
            this.nodata = false
        },
        async submit () {
            if (this.budget.details.length > 0) {
                if (this.budget.target_budget !== '') {
                    var check = await this.$refs.form.validate()
                    if (check) {
                        this.$store.dispatch('mutateCommonProperties', { loading: true })
                        const mainForm = Object.assign({}, { proposal_id: this.proposal_id, application_status: this.application_status }, this.budget)
                        const loadingState = { loading: false, listReload: false }
                        const result = await RestApi.postData(agriResearchServiceBaseUrl, proposalBudgetInfoStoreApi, mainForm)
                        loadingState.listReload = true
                        this.$store.dispatch('mutateCommonProperties', loadingState)
                        if (result.success) {
                            this.$toast.success({
                                title: this.$t('globalTrans.success'),
                                message: this.$t('globalTrans.save_msg'),
                                color: '#D6E09B'
                            })
                            return result
                        } else {
                            this.$refs.form.setErrors(result.errors)
                            this.$toast.error({
                                title: this.$t('globalTrans.error'),
                                message: this.$t('globalTrans.form_error_msg'),
                                color: '#D6E09B'
                            })
                        }
                    }
                } else {
                    this.tmpMsg = 'Target Budget is not valid!'
                }
            } else {
                this.$toast.error({
                    message: this.$i18n.locale === 'en' ? 'Add at least one item' : 'অন্তত একটি আইটেম যোগ করুন',
                    color: '#D6E09B'
                })
            }
        }
    }
}
</script>
