<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <template>
                            <div style="font-size:16px;" class="bg-primary">
                                <h5 class="text-white text-center"> {{ $t('certify_form.gen_info') }}
                                </h5>
                            </div>
                        </template>
                        <b-row class="mt-4">
                            <b-col sm="12" lg="6">
                                <ValidationProvider name="Research Type" vid="research_type" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="research_type"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('external_research.research_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="proposal.research_type"
                                            :options="researchTypeList"
                                            id="research_type"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Thematic Area" vid="thematic_area" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="thematic_area"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('research_manage.thematic_area')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        disabled
                                        v-model="general.thematic_name"
                                        id="thematic_area"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row v-if="proposal.research_type == 3">
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="organiation_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="proposal.org_id"
                                        :options="orgList"
                                        id="organiation_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="office_type_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('org_pro.office_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="proposal.office_type_id"
                                        :options="officeTypeList"
                                        id="office_type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Office Name" vid="office_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="office_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('org_pro.office')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="proposal.office_id"
                                        :options="officeList"
                                        id="office_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Division Name" vid="division_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="division_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('external_research.division_department')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="proposal.divisional_id"
                                        :options="divisionalResearchList"
                                        id="division_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Project Title (En)" vid="project_title" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="project_title"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_title_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="proposal.project_title"
                                        id="project_title"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Project Title (Bn)" vid="project_title_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="project_title_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_title_bn')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        v-model="proposal.project_title_bn"
                                        id="project_title_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="proposal.research_type !== 3">
                                <ValidationProvider name="From Date" vid="from_date" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="from_date"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.project_duation_from_date')}} <span class="text-danger">*</span>
                                    </template>
                                    <flat-pickr class="form-control"
                                        v-model="proposal.from_date"
                                        id="from_date"
                                        placeholder="Select From Date"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </flat-pickr>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="proposal.research_type !== 3">
                                <ValidationProvider name="To Date" vid="to_date" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="to_date"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('external_research.project_duation_to_date')}} <span class="text-danger">*</span>
                                    </template>
                                    <flat-pickr class="form-control"
                                        v-model="proposal.to_date"
                                        id="to_date"
                                        placeholder="Select To Date"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </flat-pickr>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6" v-if="proposal.research_type == 3">
                                <ValidationProvider name="Season" vid="season" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="season"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('external_research.duration')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="proposal.duration"
                                            :options="seasonList"
                                            id="season"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row v-if="proposal.research_type == 2" class="mt-2">
                            <b-col sm="12" style="max-width: 50%">
                                <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                    {{ $t('external_research.lead_org_and_coordinator') + ' ' + $t('sidebar.information') }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row v-if="proposal.research_type == 2">
                            <b-col lg="6" sm="6">
                                <ValidationProvider name="Organization Type" vid="org_type" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="org_type"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('research_manage.organization_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="proposal.org_type"
                                            :options="organizationType"
                                            id="org_type"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <ValidationProvider :name="proposal.org_type == 3 ? $t('research_manage.university_name', 'en') : $t('external_research.lead_org', 'en')" vid="lead_org_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="lead_org_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{ proposal.org_type == 3 ? $t('research_manage.university_name') : $t('external_research.lead_org') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="general.tmp_org_id"
                                            :options="componentOrgList"
                                            id="lead_org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Coordinator Name" vid="coord_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="coord_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        disabled
                                        v-model="general.coord_name"
                                        id="coord_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Designation" vid="coord_designation" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="coord_designation"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.designation')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        disabled
                                        v-model="general.coord_designation"
                                        id="coord_designation"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Mobile No" vid="coord_mobile_no" rules="required|numeric|max:11|min:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="coord_mobile_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input class="form-control"
                                        disabled
                                        v-model="general.coord_mobile_no"
                                        id="coord_mobile_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                             <b-col lg="6" sm="12">
                                <ValidationProvider name="Email" vid="coord_email" rules="email">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="coord_email"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.email')}}
                                    </template>
                                    <b-form-input class="form-control"
                                        disabled
                                        v-model="general.coord_email"
                                        id="coord_email"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
            <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }" v-if="proposal.research_type == 2">
                <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
                    <b-overlay>
                        <b-row v-if="proposal.research_type == 2" class="mt-3">
                            <b-col sm="12" style="max-width: 50%">
                                <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                    {{ $t('external_research.component_org') }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="6">
                                <ValidationProvider name="Organization Type" vid="org_type" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="org_type"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('research_manage.organization_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="compOrgDetails.org_type"
                                            :options="organizationType"
                                            id="org_type"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" lg="6">
                                <ValidationProvider :name="compOrgDetails.org_type == 3 ? $t('research_manage.university_name', 'en') : $t('external_research.component_org', 'en')" vid="component_org_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="component_org_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{ compOrgDetails.org_type == 3 ? $t('research_manage.university_name') : $t('external_research.component_org')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="compOrgDetails.tmp_org_id"
                                            :options="tmpComponentOrgList"
                                            id="component_org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button type="submit" variant="success">{{ $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                            <b-col xl="12" lg="12" sm="12">
                                <b-table-simple bordered>
                                <thead class="text-white bg-primary">
                                    <tr>
                                        <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                        <th scope="col">{{ $t('research_manage.organization_type') }}</th>
                                        <th scope="col">{{ $t('research_manage.org_or_univ_name') }}</th>
                                        <th class="text-center mytd">{{ $t('globalTrans.action') }}</th>
                                    </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in proposal.componentOrgs" :key="index">
                                        <b-td class="text-center mytd">{{ $n(index + 1) }}</b-td>
                                        <b-td>
                                            {{ getOrgTypeName(item.org_type) }}
                                        </b-td>
                                        <b-td>
                                            {{ getComponentOrgName(item.org_type, item.component_org_id) }}
                                        </b-td>
                                        <b-td class="text-center mytd">
                                            <b-button type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="nodata">
                                        <td colspan="4" class="text-center">
                                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                        </td>
                                    </b-tr>
                                </b-tbody>
                                </b-table-simple>
                            </b-col>
                            <!-- <b-col sm="12" lg="6">
                                <ValidationProvider name="Expense Year" vid="expense_yeardd" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="expense_yeardd"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('external_research.expense_year')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="general.expense_year"
                                            :options="expenseYearList"
                                            id="expense_yeardd"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col> -->
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalGeneralStoreApi, getCoordinatorInfoApi } from '../../../api/routes'

export default {
    props: ['proposal_id', 'proposal'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            officeTypeList: [],
            officeList: [],
            divisionalResearchList: [],
            general: {
                // expense_year: 0,
                thematic_name: '',
                coord_name: '',
                coord_designation: '',
                coord_mobile_no: '',
                coord_phone_no: '',
                coord_email: '',
                tmp_org_id: 0
            },
            coord_info: {},
            compOrgDetails: {
                org_type: 0,
                tmp_org_id: 0,
                component_org_id: 0
            },
            componentOrgList: [],
            tmpComponentOrgList: [],
            nodata: true,
            unitLoad: false
            // expenseYearList: []
        }
    },
    created () {
        if (this.$route.query.circluar_id) {
            // const tmp = this.getCircularData()
            // this.general.thematic_name = this.getThematicName(tmp.thematic_area_id)
            this.proposal.circular_main_id = this.$route.query.circluar_id
        }

        if (this.$route.query.t) {
            this.general.thematic_name = this.getThematicName(this.$route.query.t)
        }

        if (this.$route.query.id) {
            this.general.thematic_name = this.getThematicName(this.proposal.thematic_area_id)
            if (this.proposal.research_type === 2) {
                this.getLeadOrgList()
                if (this.componentOrgList.length > 0) {
                    if (this.proposal.org_type === 1) {
                        const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === parseInt(this.proposal.lead_org_id))
                        this.general.tmp_org_id = Obj.org_id
                    } else {
                        this.general.tmp_org_id = this.proposal.lead_org_id
                    }
                }
            }

            if (this.proposal.research_type === 3) {
                this.getOfficeTypeList()
                this.getDivisionalOrgList()
                this.getOfficeList()
            }
            this.nodata = false
        }
    },
    watch: {
        // 'proposal.to_date': function (newValue) {
        //     this.expenseYearList = this.getExpenseYearList()
        // },
        'proposal.org_id': function (newVal, oldVal) {
            this.getOfficeTypeList()
            this.getDivisionalOrgList()
        },
        'proposal.office_type_id': function (newVal, oldVal) {
            this.getOfficeList()
        },
        'general.tmp_org_id': function (newValue) {
            this.getCoordinatorInfo(newValue)
        },
        'proposal.org_type': function (newValue) {
            this.getLeadOrgList()
        },
        'compOrgDetails.org_type': function (newValue) {
            this.getTmpLeadOrgList()
        },
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getLeadOrgList()
                this.getTmpLeadOrgList()
                this.localeCoordInfo()
            }
            if (this.proposal.org_id) {
                this.getOfficeTypeList()
            }
            if (this.proposal.office_type_id) {
                this.getOfficeList()
            }
        }
    },
    computed: {
        seasonList () {
            const seasonList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Kharif-1' : 'খরিফ-১', text_en: 'Kharif-1', text_bn: 'খরিফ-১' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Kharif-2' : 'খরিফ-২', text_en: 'Kharif-2', text_bn: 'খরিফ-২' }
            ]
            return seasonList
        },
        orgList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        currentLocale () {
            return this.$i18n.locale
        },
        researchTypeList: function () {
            return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.researchTypeList.map(item => {
                return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
            })
        },
        organizationType: function () {
            return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.organizationType.map(item => {
                return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
            })
        }
    },
    mounted () {
        //
    },
    methods: {
        getDivisionalOrgList () {
            this.divisionalResearchList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.divisionalResearchList.filter(item => item.status === 1 && item.org_id === parseInt(this.proposal.org_id)).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getOfficeTypeList () {
            this.officeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === this.proposal.org_id).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getOfficeList () {
            this.officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === this.proposal.office_type_id).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        setCurrentFiscalYear (fiscalYearList, date) {
            if (fiscalYearList.length === 0) {
                return
            }
            const currentDate = new Date(date)
            const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
            /** Define the year position  */
            const yearPosition = month < 7 ? 5 : 0
            const yearStr = `${year}`
            let currentFiscalYearId = 0
            fiscalYearList.forEach(element => {
                if (element.text_en.indexOf(yearStr) === yearPosition) {
                    currentFiscalYearId = element.value
                }
            })
            return currentFiscalYearId
        },
        getExpenseYearList () {
            if (this.proposal.from_date !== '' && this.proposal.to_date !== '') {
                const tmpFiscalList = []
                const fiscalYear = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
                const startID = this.setCurrentFiscalYear(fiscalYear, this.proposal.from_date)
                const endId = this.setCurrentFiscalYear(fiscalYear, this.proposal.to_date)

                let start = false
                fiscalYear.forEach(element => {
                    if (startID === element.value) {
                        start = true
                    }
                    if (start) {
                        tmpFiscalList.push(element)
                    }
                    if (endId === element.value) {
                        start = false
                    }
                })
                return tmpFiscalList
            }
        },
        async getCoordinatorInfo (Id) {
            this.unitLoad = true
            let myId = ''
            if (this.proposal.org_type === 1) {
                const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.org_id === parseInt(Id))
                myId = Obj.value
            } else {
                myId = Id
            }
            const result = await RestApi.getData(agriResearchServiceBaseUrl, getCoordinatorInfoApi, { id: myId })
            if (!result.success) {
                this.general.coord_name = ''
                this.general.coord_designation = ''
                this.general.coord_mobile_no = ''
                this.general.coord_email = ''
            } else {
                const data = result.data
                this.proposal.lead_org_id = data.id
                this.coord_info = data
                if (this.coord_info) {
                    this.localeCoordInfo()
                }
            }
            this.unitLoad = false
        },
        localeCoordInfo () {
            this.general.coord_name = this.$i18n.locale === 'en' ? this.coord_info.coord_name : this.coord_info.coord_name_bn
            this.general.coord_designation = this.$i18n.locale === 'en' ? this.coord_info.coord_designation : this.coord_info.coord_designation_bn
            this.general.coord_mobile_no = this.coord_info.coord_mobile_no
            this.general.coord_email = this.coord_info.coord_email
        },
        getLeadOrgList () {
            this.componentOrgList = []
            if (this.proposal.org_type === 2 || this.proposal.org_type === 3) {
                this.componentOrgList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === parseInt(this.proposal.org_type))
                .map(obj => {
                    return Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                })
            } else {
                const tmpList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === 1)
                tmpList.map(newItem => {
                    this.$store.state.ExternalUserIrrigation.commonObj.orgList.filter(itm => itm.value === newItem.org_id)
                    .map(obj => {
                        const Org = Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                        this.componentOrgList.push(Org)
                    })
                })
            }
        },
        getTmpLeadOrgList () {
            this.tmpComponentOrgList = []
            if (this.compOrgDetails.org_type === 2 || this.compOrgDetails.org_type === 3) {
                this.tmpComponentOrgList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === parseInt(this.compOrgDetails.org_type))
                .map(obj => {
                    return Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                })
            } else {
                const tmpList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.filter(item => item.org_type === 1)
                tmpList.map(newItem => {
                    this.$store.state.ExternalUserIrrigation.commonObj.orgList.filter(itm => itm.value === newItem.org_id)
                    .map(obj => {
                        const Org = Object.assign({}, obj, { value: obj.value, text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn })
                        this.tmpComponentOrgList.push(Org)
                    })
                })
            }
        },
        getOrgTypeName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.organizationType.find(item => item.value === Id)
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        },
        getComponentOrgName (type, Id) {
            if (type === 1) {
                const newObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                const Obj = this.$store.state.ExternalUserIrrigation.commonObj.orgList.find(item => item.value === newObj.org_id)
                return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
            } else {
                const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
            }
        },
        deleteItem (index) {
            this.proposal.componentOrgs.splice(index, 1)
            if (this.proposal.componentOrgs.length === 0) {
                this.nodata = true
            }
        },
        async addItem () {
            const orgId = this.compOrgDetails.tmp_org_id

            const newData = this.proposal.componentOrgs.find(item => item.tmp_org_id === orgId)
            if (typeof newData === 'undefined') {
                if (this.compOrgDetails.org_type === 1) {
                    const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.org_id === parseInt(this.compOrgDetails.tmp_org_id))
                    this.compOrgDetails.component_org_id = Obj.value
                } else {
                    this.compOrgDetails.component_org_id = this.compOrgDetails.tmp_org_id
                }
                this.proposal.componentOrgs.push(JSON.parse(JSON.stringify(this.compOrgDetails)))
                this.compOrgDetails.tmp_org_id = 0
                this.compOrgDetails.component_org_id = 0
            } else {
                this.$toast.error({
                    message: this.$i18n.locale === 'en' ? 'Component organization already exists' : 'কম্পোনেন্ট সংস্থা ইতিমধ্যেই বিদ্যমান',
                    color: '#D6E09B'
                })
            }
            this.$nextTick(() => {
                this.$refs.form1.reset()
            })
            this.nodata = false
        },
        getThematicName (Id) {
            const thematicAreaObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(Item => Item.value === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                return thematicAreaObj.text_en
            } else {
                return thematicAreaObj.text_bn
            }
        },
        // getCircularData () {
        //     const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.circluar_id))
        //     return JSON.parse(JSON.stringify(tmpData))
        // },
        async submit () {
            let lengthCheck = false
            if (this.proposal.research_type === 2) {
                if (this.proposal.componentOrgs.length > 0) {
                    lengthCheck = true
                } else {
                    lengthCheck = false
                }
            } else {
                lengthCheck = true
            }
            if (lengthCheck) {
                var check = await this.$refs.form.validate()
                if (check) {
                    this.$store.dispatch('mutateCommonProperties', { loading: true })
                    const loadingState = { loading: false, listReload: false }
                    this.proposal.proposal_id = this.proposal_id
                    const result = await RestApi.postData(agriResearchServiceBaseUrl, proposalGeneralStoreApi, this.proposal)
                    loadingState.listReload = true
                    this.$store.dispatch('mutateCommonProperties', loadingState)
                    if (result.success) {
                        this.$toast.success({
                            title: this.$t('globalTrans.success'),
                            message: this.$t('globalTrans.save_msg'),
                            color: '#D6E09B'
                        })
                        return result
                    } else {
                        this.$toast.error({
                            title: this.$t('globalTrans.error'),
                            message: this.$t('globalTrans.form_error_msg'),
                            color: '#D6E09B'
                        })
                    }
                }
            } else {
                this.$toast.error({
                    message: this.$i18n.locale === 'en' ? 'Add at least one item' : 'অন্তত একটি আইটেম যোগ করুন',
                    color: '#D6E09B'
                })
            }
        }
    }
}
</script>
